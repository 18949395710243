import { getIsLogined } from '@/utility/auth';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

function PublicRoute(props) {
  const { layout: Layout, element, loading } = props;

  if (getIsLogined()) {
    return <Navigate to="/admin" replace={true} />;
  }

  return (
    <Layout>
      <Suspense fallback={loading}>{element}</Suspense>
    </Layout>
  );
}

export default PublicRoute;
