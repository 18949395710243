import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const seIndexSnapshotMenuRegistry = {
    company_seIndexSnapshot: {
        label: "Se Index Snapshot",
        link: PAGE_URL.SE_INDEX_SNAPSHOT_PAGE,
        key: PAGE_URL.SE_INDEX_SNAPSHOT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default seIndexSnapshotMenuRegistry;
