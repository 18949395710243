import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const otpTrackingMenuRegistry = {
    notification_otpTracking: {
        label: "Otp Tracking",
        link: PAGE_URL.OTP_TRACKING_PAGE,
        key: PAGE_URL.OTP_TRACKING_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default otpTrackingMenuRegistry;
