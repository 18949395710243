import Storage from '@/constants/storage';

const getPrefixKey = (key) => `${Storage.PREFIX}-${key}`;

const localStorageService = {
  length: localStorage.length,
  set: (key, value) => {
    localStorage.setItem(getPrefixKey(key), value);
  },
  get: (key) => {
    return localStorage.getItem(getPrefixKey(key));
  },
  remove: (key) => {
    localStorage.removeItem(getPrefixKey(key));
  },
  clear: () => {
    localStorage.clear();
  },
  key: (index) => {
    return localStorage.key(index);
  },
};

export default localStorageService;
