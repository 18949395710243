import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const permissionGroupUserMenuRegistry = {
    core_permissionGroupUser: {
        label: "Permission Group User",
        link: PAGE_URL.PERMISSION_GROUP_USER_PAGE,
        key: PAGE_URL.PERMISSION_GROUP_USER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default permissionGroupUserMenuRegistry;
