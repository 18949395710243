import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import config from './config/config';
import { ThemeProvider2 } from './contexts/ThemeContext';
import Routes from './routes';
import {PermissionProvider} from "./contexts/PermissionContext";
import Storage from "@/constants/storage";
import {ModalProvider} from "@/contexts/ModalContext";
import ModalManager from "@/modal/ModalManager";
import localStorageService from "@/utility/localStorageService";

const { theme } = config;

const App = () => {
  const rtl = useSelector((state) => state.ChangeLayoutMode.rtl);
  const topMenu = useSelector((state) => state.ChangeLayoutMode.topMenu);
  const mainContent = useSelector((state) => state.ChangeLayoutMode.mode);
  const token = localStorageService.get(Storage.ACCESS_TOKEN);
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <ThemeProvider2>
          <PermissionProvider initialToken={token}>
            <ModalProvider>
              <Routes />
              <ModalManager />
            </ModalProvider>
          </PermissionProvider>
        </ThemeProvider2>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default App;
