import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const featuredContentMenuRegistry = {
    community_featuredContent: {
        label: "Featured Content",
        link: PAGE_URL.FEATURED_CONTENT_PAGE,
        key: PAGE_URL.FEATURED_CONTENT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default featuredContentMenuRegistry;
