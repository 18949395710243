import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const ConfigSystem = React.lazy(() =>
    import('../ConfigSystem')
);
const configSystemRoutesRegistry = [
    {
        path: PAGE_URL.CONFIG_SYSTEM_PAGE,
        element: <ConfigSystem />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default configSystemRoutesRegistry;
