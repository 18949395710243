import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userReferralRewardMenuRegistry = {
    core_userReferralReward: {
        label: "User Referral Reward",
        link: PAGE_URL.USER_REFERRAL_REWARD_PAGE,
        key: PAGE_URL.USER_REFERRAL_REWARD_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userReferralRewardMenuRegistry;
