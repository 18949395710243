import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const MembershipSubscriptionUser = React.lazy(() =>
    import('../MembershipSubscriptionUser')
);
const membershipSubscriptionUserRoutesRegistry = [
    {
        path: PAGE_URL.MEMBERSHIP_SUBSCRIPTION_USER_PAGE,
        element: <MembershipSubscriptionUser />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default membershipSubscriptionUserRoutesRegistry;
