import { createSlice } from '@reduxjs/toolkit';
const initialState = { idUser: '', visible: false };

export const poupInfoSLice = createSlice({
  name: 'poupInfo',
  initialState,
  reducers: {
    setIdUser: (state, action) => {
      state.idUser = action.payload;
    },
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
});
export const { setIdUser, setVisible } = poupInfoSLice.actions;

export default poupInfoSLice.reducer;
