import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const notificationSettingMenuRegistry = {
    notification_notificationSetting: {
        label: "Notification Setting",
        link: PAGE_URL.NOTIFICATION_SETTING_PAGE,
        key: PAGE_URL.NOTIFICATION_SETTING_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default notificationSettingMenuRegistry;
