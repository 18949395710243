import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userCashDetailMenuRegistry = {
    core_userCashDetail: {
        label: "User Cash Detail",
        link: PAGE_URL.USER_CASH_DETAIL_PAGE,
        key: PAGE_URL.USER_CASH_DETAIL_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userCashDetailMenuRegistry;
