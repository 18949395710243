import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const smsTrackingLogMenuRegistry = {
    notification_smsTrackingLog: {
        label: "Sms Tracking Log",
        link: PAGE_URL.SMS_TRACKING_LOG_PAGE,
        key: PAGE_URL.SMS_TRACKING_LOG_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default smsTrackingLogMenuRegistry;
