import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const permissionGroupMenuRegistry = {
    core_permissionGroup: {
        label: "Permission Group",
        link: PAGE_URL.PERMISSION_GROUP_PAGE,
        key: PAGE_URL.PERMISSION_GROUP_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default permissionGroupMenuRegistry;
