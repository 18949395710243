import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const SmSubscribeTracking = React.lazy(() =>
    import('../SmSubscribeTracking')
);
const smSubscribeTrackingRoutesRegistry = [
    {
        path: PAGE_URL.SM_SUBSCRIBE_TRACKING_PAGE,
        element: <SmSubscribeTracking />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default smSubscribeTrackingRoutesRegistry;
