import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const paymentModeMenuRegistry = {
    core_paymentMode: {
        label: "Payment Mode",
        link: PAGE_URL.PAYMENT_MODE_PAGE,
        key: PAGE_URL.PAYMENT_MODE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default paymentModeMenuRegistry;
