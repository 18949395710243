import jwtDecode from 'jwt-decode';
import Storage from '@/constants/storage';
import localStorageService from "@/utility/localStorageService";

export const getIsLogined = () => {
  const token = localStorageService.get(Storage.ACCESS_TOKEN);

  if (!token) return false;

  const decode = jwtDecode(token);
  const auth = decode?.auth?.split(',');
  const exp = decode?.exp;
  const currentTime = Date.now() / 1000;
  const isExpired = exp < currentTime

  if (isExpired) {
    localStorageService.remove(Storage.ACCESS_TOKEN);
    localStorageService.remove(Storage.REFRESH_TOKEN);
    return false;
  }

  const roles = ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_EMPLOYEE', 'ROLE_CONSOLE'];

  const isManager = roles.some((role) => auth.includes(role));
  if (!isManager) {
    localStorageService.remove(Storage.ACCESS_TOKEN);
    localStorageService.remove(Storage.REFRESH_TOKEN);
    return false;
  }

  return true;
};

export const getUserInfo = () => {
  try {
    const userInfo = localStorageService.get(Storage.USER_INFO);
    if (!userInfo) return null;
    return JSON.parse(userInfo);
  } catch (error) {
    return null;
  }
}
