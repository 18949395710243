import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const tagUserMenuRegistry = {
    crm_tagUser: {
        label: "Tag User",
        link: PAGE_URL.TAG_USER_PAGE,
        key: PAGE_URL.TAG_USER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default tagUserMenuRegistry;
