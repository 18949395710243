import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const nebulaTradingTransactionMenuRegistry = {
    personalize_nebulaTradingTransaction: {
        label: "Nebula Trading Transaction",
        link: PAGE_URL.NEBULA_TRADING_TRANSACTION_PAGE,
        key: PAGE_URL.NEBULA_TRADING_TRANSACTION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default nebulaTradingTransactionMenuRegistry;
