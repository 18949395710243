import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const notificationSnapshotMenuRegistry = {
    notification_notificationSnapshot: {
        label: "Notification Snapshot",
        link: PAGE_URL.NOTIFICATION_SNAPSHOT_PAGE,
        key: PAGE_URL.NOTIFICATION_SNAPSHOT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default notificationSnapshotMenuRegistry;
