import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const campaignZoneMenuRegistry = {
    crm_campaignZone: {
        label: "Campaign Zone",
        link: PAGE_URL.CAMPAIGN_ZONE_PAGE,
        key: PAGE_URL.CAMPAIGN_ZONE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default campaignZoneMenuRegistry;
