import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const NebulaTradingTransaction = React.lazy(() =>
    import('../NebulaTradingTransaction')
);
const nebulaTradingTransactionRoutesRegistry = [
    {
        path: PAGE_URL.NEBULA_TRADING_TRANSACTION_PAGE,
        element: <NebulaTradingTransaction />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default nebulaTradingTransactionRoutesRegistry;
