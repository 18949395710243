import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const notificationTypeMenuRegistry = {
    notification_notificationType: {
        label: "Notification Type",
        link: PAGE_URL.NOTIFICATION_TYPE_PAGE,
        key: PAGE_URL.NOTIFICATION_TYPE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default notificationTypeMenuRegistry;
