import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const taIndicatorConfigMenuRegistry = {
    personalize_taIndicatorConfig: {
        label: "Ta Indicator Config",
        link: PAGE_URL.TA_INDICATOR_CONFIG_PAGE,
        key: PAGE_URL.TA_INDICATOR_CONFIG_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default taIndicatorConfigMenuRegistry;
