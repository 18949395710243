import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userForceSessionMenuRegistry = {
    core_userForceSession: {
        label: "User Force Session",
        link: PAGE_URL.USER_FORCE_SESSION_PAGE,
        key: PAGE_URL.USER_FORCE_SESSION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userForceSessionMenuRegistry;
