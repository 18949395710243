import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const miniAppConfigMenuRegistry = {
    core_miniAppConfig: {
        label: "Mini App Config",
        link: PAGE_URL.MINI_APP_CONFIG_PAGE,
        key: PAGE_URL.MINI_APP_CONFIG_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default miniAppConfigMenuRegistry;
