import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const appReleaseNoticesMenuRegistry = {
    core_appReleaseNotices: {
        label: "App Release Notices",
        link: PAGE_URL.APP_RELEASE_NOTICES_PAGE,
        key: PAGE_URL.APP_RELEASE_NOTICES_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default appReleaseNoticesMenuRegistry;
