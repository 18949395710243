import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const UserCashTransaction = React.lazy(() =>
    import('../UserCashTransaction')
);
const userCashTransactionRoutesRegistry = [
    {
        path: PAGE_URL.USER_CASH_TRANSACTION_PAGE,
        element: <UserCashTransaction />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default userCashTransactionRoutesRegistry;
