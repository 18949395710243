import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const companyReportMenuRegistry = {
    crm_companyReport: {
        label: "Company Report",
        link: PAGE_URL.COMPANY_REPORT_PAGE,
        key: PAGE_URL.COMPANY_REPORT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default companyReportMenuRegistry;
