import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { Button, Col, Layout, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Search from '../components/utilities/auth-info/Search';
import AuthInfo from '../components/utilities/auth-info/info';
import { GlobalUtilityStyle, PaginationStyle } from '../container/styled';
import { ReactComponent as MySVG } from '../static/img/icon/left-bar.svg';
import MenuItems from './MenuItems';
import { LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
import TopMenu from './TopMenu';
import localStorageService from '@/utility/localStorageService';
import Storage from '@/constants/storage';

const { theme } = require('../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;

function AdminLayout(props) {
  const { children } = props;

  const layoutMode = useSelector((state) => state.ChangeLayoutMode.mode);
  const rtl = useSelector((state) => state.ChangeLayoutMode.rtlData);
  const topMenu = useSelector((state) => state.ChangeLayoutMode.topMenu);

  const [collapsed, setCollapsed] = React.useState(
    localStorageService.get(Storage.COLLAPSED) === 'true' ? true : false
  );
  const [hide, setHide] = React.useState(true);

  const left = !rtl ? 'left' : 'right';

  React.useEffect(() => {
    const updateDimensions = () => {
      setCollapsed(true);
      localStorageService.set(Storage.COLLAPSED, 'true');
    };

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    if (collapsed) {
      localStorageService.set(Storage.COLLAPSED, 'false');
    } else {
      localStorageService.set(Storage.COLLAPSED, 'true');
    }
  };
  const width = 990;
  const toggleCollapsedMobile = () => {
    if (window.innerWidth <= width) {
      setCollapsed(!collapsed);
      if (collapsed) {
        localStorageService.set(Storage.COLLAPSED, 'false');
      } else {
        localStorageService.set(Storage.COLLAPSED, 'true');
      }
    }
  };

  const onShowHide = () => {
    setHide(!hide);
  };

  const SideBarStyle = {
    margin: '60px 0 0 0',
    padding: `${!rtl ? '20px 20px 55px 0' : '20px 0 55px 20px'}`,
    overflowY: 'auto',
    height: '100vh',
    position: 'fixed',
    [left]: 0,
    zIndex: 988,
  };

  const renderThumb = ({ style }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '4px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '0px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return (
      <div
        className="[&>div]:bg-regular dark:[&>div]:bg-[#32333f]"
        style={thumbStyle}
      />
    );
  };

  const renderView = ({ style }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: (collapsed ? '-19px' : '-30px'),
    };
    return <div style={{ ...style, ...customStyle }} />;
  };

  return (
    <GlobalUtilityStyle>
      <PaginationStyle>
        <LayoutContainer>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
              className="p-0 flex items-center justify-between bg-white dark:bg-[#1b1e2b] dark:shadow-[0_5px_20px_rgba(160,160,160,.02)] h-[60px] z-998"
            >
              <div className="flex flex-row items-center flex-1 h-full">
                <div className=" rtl:ssm:pr-[15px] ltr:pr-5 rtl:pl-5 ltr:ssm:pl-[15px] ltr:ssm:pr-[15px] rtl:ssm::pl:[15px] ltr:pl-[25px] rtl:pr-[25px] xs:ltr:pl-[20px] xs:rtl:pr-[20px] min-w-[240px] ssm:min-w-[220px] xs:min-w-[170px] h-full grid align-middle dark:bg-[#323541]">
                  <div className="flex items-center justify-between">
                    <Link to="/admin">
                      <img
                        className="w-full max-w-[120px] xs:max-w-[100px]"
                        src={
                          layoutMode === 'lightMode'
                            ? require(`../static/img/logo_dark.svg`).default
                            : require(`../static/img/logo_white.svg`).default
                        }
                        alt=""
                      />
                    </Link>

                    {!topMenu || window.innerWidth <= 991 ? (
                      <Button
                        type="link"
                        className="p-0 bg-transparent border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary text-[#525768] hover:text-primary"
                        onClick={toggleCollapsed}
                      >
                        <MySVG />
                      </Button>
                    ) : null}
                  </div>
                </div>

                <Row className="flex items-center justify-between flex-auto ltr:mr-[10px] rtl:ml-[10px] [&>div:first-child]:flex [&>div]:items-center ">
                  <Col flex={'auto'}>
                    <TopMenu />
                  </Col>

                  <Col className="flex flex-row items-center md:hidden">
                    {topMenu && window.innerWidth > 991 ? (
                      <TopMenuSearch>
                        <div className="flex top-right-wrap">
                          <AuthInfo />
                        </div>
                      </TopMenuSearch>
                    ) : (
                      <AuthInfo />
                    )}
                  </Col>
                </Row>

                <div className="hidden md:flex items-center ltr:pr-[25px] rtl:pl-[25px] ltr:ssm:pr-[10px] rtl:ssm:pl-[10px]">
                  <Search />

                  <Link
                    className="inline-flex text-light dark:text-white60"
                    onClick={onShowHide}
                    to="#"
                  >
                    <UilEllipsisV className="w-[18px] h-[18px]" />
                  </Link>
                </div>
              </div>
            </Header>

            <Row>
              <Col md={0} sm={24} xs={24}>
                <SmallScreenAuthInfo hide={hide}>
                  <AuthInfo rtl={rtl} />
                </SmallScreenAuthInfo>
              </Col>
            </Row>

            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={theme}>
                  <Sider
                    width={200}
                    style={SideBarStyle}
                    collapsed={collapsed}
                    collapsedWidth={64}
                    theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                  >
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      // renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumb}
                      renderView={renderView}
                      renderTrackVertical={renderTrackVertical}
                    >
                      <MenuItems
                        topMenu={topMenu}
                        toggleCollapsed={toggleCollapsedMobile}
                        collapsed={collapsed}
                      />
                    </Scrollbars>
                  </Sider>
                </ThemeProvider>
              ) : null}

              <Layout className="atbd-main-layout">
                <Content>
                  {children}
                </Content>
              </Layout>
            </Layout>
          </Layout>

          {window.innerWidth <= 991 ? (
            <span
              className={collapsed ? 'hexadash-shade' : 'hexadash-shade show'}
              onClick={toggleCollapsed}
            />
          ) : (
            ''
          )}
        </LayoutContainer>
      </PaginationStyle>
    </GlobalUtilityStyle>
  );
}

export default AdminLayout;
