import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PopupUser = React.lazy(() =>
    import('../PopupUser')
);
const popupUserRoutesRegistry = [
    {
        path: PAGE_URL.POPUP_USER_PAGE,
        element: <PopupUser />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default popupUserRoutesRegistry;
