import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userCashMenuRegistry = {
    core_userCash: {
        label: "User Cash",
        link: PAGE_URL.USER_CASH_PAGE,
        key: PAGE_URL.USER_CASH_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userCashMenuRegistry;
