import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const notificationModuleMenuRegistry = {
    notification_notificationModule: {
        label: "Notification Module",
        link: PAGE_URL.NOTIFICATION_MODULE_PAGE,
        key: PAGE_URL.NOTIFICATION_MODULE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default notificationModuleMenuRegistry;
