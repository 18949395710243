import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const bCIndustryMenuRegistry = {
    company_bcIndustry: {
        label: "Bc Industry",
        link: PAGE_URL.B_C_INDUSTRY_PAGE,
        key: PAGE_URL.B_C_INDUSTRY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default bCIndustryMenuRegistry;
