import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const UserLoginActivity = React.lazy(() =>
    import('../UserLoginActivity')
);
const userLoginActivityRoutesRegistry = [
    {
        path: PAGE_URL.USER_LOGIN_ACTIVITY_PAGE,
        element: <UserLoginActivity />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default userLoginActivityRoutesRegistry;
