import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const permissionMenuRegistry = {
    core_permission: {
        label: "Permission",
        link: PAGE_URL.PERMISSION_PAGE,
        key: PAGE_URL.PERMISSION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default permissionMenuRegistry;
