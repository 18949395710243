import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const companyMenuRegistry = {
    company_company: {
        label: "Company",
        link: PAGE_URL.COMPANY_PAGE,
        key: PAGE_URL.COMPANY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default companyMenuRegistry;
