import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userLoginActivityMenuRegistry = {
    core_userLoginActivity: {
        label: "User Login Activity",
        link: PAGE_URL.USER_LOGIN_ACTIVITY_PAGE,
        key: PAGE_URL.USER_LOGIN_ACTIVITY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userLoginActivityMenuRegistry;
