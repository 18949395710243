import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const popupInfoMenuRegistry = {
    crm_popupInfo: {
        label: "Popup Info",
        link: PAGE_URL.POPUP_INFO_PAGE,
        key: PAGE_URL.POPUP_INFO_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default popupInfoMenuRegistry;
