console.time('router-registry');
const importAll = (r) => r.keys().map(r);
const routeModules = importAll(require.context('../modules', true, /registry\/routes\.js$/));

const allRoutes = routeModules.reduce((acc, module) => {
    if (module.default) {
        return [...acc, ...module.default];
    }
    return acc;
}, []);
console.timeEnd('router-registry');
export default allRoutes;
