import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const Segments = React.lazy(() =>
    import('../Segments')
);
const SegmentDetail = React.lazy(() =>
    import('../SegmentDetail')
);
const SegmentCreation = React.lazy(() =>
    import('../SegmentCreation')
);
const segmentsRoutesRegistry = [
    {
        path: PAGE_URL.SEGMENTS_PAGE,
        element: <Segments />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.SEGMENTS_PAGE}/:id`,
        element: <SegmentDetail />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.SEGMENTS_PAGE}/create`,
        element: <SegmentCreation />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default segmentsRoutesRegistry;
