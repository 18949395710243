import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const workflowTriggerUserMenuRegistry = {
    crm_workflowTriggerUser: {
        label: "Workflow Trigger User",
        link: PAGE_URL.WORKFLOW_TRIGGER_USER_PAGE,
        key: PAGE_URL.WORKFLOW_TRIGGER_USER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default workflowTriggerUserMenuRegistry;
