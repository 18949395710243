import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const Zone = React.lazy(() =>
    import('../Zone')
);
const zoneRoutesRegistry = [
    {
        path: PAGE_URL.ZONE_PAGE,
        element: <Zone />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default zoneRoutesRegistry;
