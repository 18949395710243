import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const fileManagerMenuRegistry = {
    core_fileManager: {
        label: "FileManager",
        link: PAGE_URL.FILE_MANAGER_PAGE,
        key: PAGE_URL.FILE_MANAGER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default fileManagerMenuRegistry;
