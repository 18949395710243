import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const UserReferralPlan = React.lazy(() =>
    import('../UserReferralPlan')
);
const userReferralPlanRoutesRegistry = [
    {
        path: PAGE_URL.USER_REFERRAL_PLAN_PAGE,
        element: <UserReferralPlan />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default userReferralPlanRoutesRegistry;
