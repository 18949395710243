import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const portfolioStockMenuRegistry = {
    personalize_portfolioStock: {
        label: "Portfolio Stock",
        link: PAGE_URL.PORTFOLIO_STOCK_PAGE,
        key: PAGE_URL.PORTFOLIO_STOCK_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default portfolioStockMenuRegistry;
