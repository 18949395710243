import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PermissionGroupUser = React.lazy(() =>
    import('../PermissionGroupUser')
);
const permissionGroupUserRoutesRegistry = [
    {
        path: PAGE_URL.PERMISSION_GROUP_USER_PAGE,
        element: <PermissionGroupUser />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default permissionGroupUserRoutesRegistry;
