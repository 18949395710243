import './static/css/style.css';
import 'antd/dist/antd.less';
import './static/css/reset.css';
import './static/css/custom-style.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './i18n/config';
import store from './redux/store';
import { ThemeProvider } from './workflow/theme';

import CssBaseline from '@material-ui/core/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 600000, // 10 mins
    },
  },
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline />
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        <App />
      </ThemeProvider>
    </Provider>
  </QueryClientProvider>,
);
