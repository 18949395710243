import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const membershipSubscriptionUserMenuRegistry = {
    core_membershipSubscriptionUser: {
        label: "Membership Subscription User",
        link: PAGE_URL.MEMBERSHIP_SUBSCRIPTION_USER_PAGE,
        key: PAGE_URL.MEMBERSHIP_SUBSCRIPTION_USER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default membershipSubscriptionUserMenuRegistry;
