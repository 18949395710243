import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const contentTemplateMenuRegistry = {
    crm_contentTemplate: {
        label: "Content Template",
        link: PAGE_URL.CONTENT_TEMPLATE_PAGE,
        key: PAGE_URL.CONTENT_TEMPLATE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default contentTemplateMenuRegistry;
