import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const ServiceSchema = React.lazy(() =>
    import('../ServiceSchema')
);
const serviceSchemaRoutes = [
    {
        path: PAGE_URL.SERVICE_REGISTER_PAGE,
        element: <ServiceSchema />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default serviceSchemaRoutes;
