import React from 'react';
import Heading from "@/components/heading/heading";

function AccessDenied() {
    return (
        <main className="min-h-[200px] lg:min-h-[200px] p-[50px]">
            <div className="flex justify-center items-center flex-col text-center">
                <svg width="300" height="100" viewBox="0 0 400 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M129.206 16.4321L266.152 7.86815C280.497 6.97156 292.586 18.4572 292.431 32.8335L291.364 128.382C291.21 141.939 280.188 152.837 266.631 152.837H137.074C124.104 152.837 113.345 142.82 112.402 129.897L106.08 42.8969C105.091 29.1853 115.479 17.2823 129.206 16.4321Z" fill="#FDEFED"/>
                    <path d="M245.533 148.118L265.891 34.9707C267.072 28.4083 262.709 22.1313 256.147 20.9506L178.692 7.01421C172.13 5.83345 165.852 10.1961 164.672 16.7585L144.313 129.906C143.133 136.468 147.495 142.745 154.058 143.926L231.513 157.862C238.075 159.043 244.352 154.68 245.533 148.118Z" fill="#F67C81"/>
                    <path d="M240.151 127.408V12.4439C240.151 5.77612 234.745 0.37085 228.078 0.37085L149.379 0.37085C142.711 0.37085 137.306 5.77612 137.306 12.4439V127.408C137.306 134.076 142.711 139.481 149.379 139.481H228.078C234.745 139.481 240.151 134.076 240.151 127.408Z" fill="white"/>
                    <path d="M209.367 62.6249L197.79 32.8299C196.724 30.0914 193.182 29.3863 191.149 31.5017L168.963 54.5079C166.913 56.6232 168.028 60.1816 170.914 60.7555L205.153 67.6426C208.039 68.2165 210.433 65.3797 209.367 62.6249V62.6249Z" fill="#ED5A6E"/>
                    <path d="M190.066 54.0651C189.361 53.9175 188.902 53.2616 189.001 52.5565L190.411 42.685C190.558 41.6027 191.608 40.8648 192.674 41.078C193.74 41.2912 194.412 42.3734 194.149 43.4229L191.641 53.0648C191.46 53.7536 190.772 54.1799 190.083 54.0487L190.066 54.0651Z" fill="white"/>
                    <path d="M189.378 58.6404C190.12 58.6404 190.722 58.0384 190.722 57.2958C190.722 56.5532 190.12 55.9511 189.378 55.9511C188.635 55.9511 188.033 56.5532 188.033 57.2958C188.033 58.0384 188.635 58.6404 189.378 58.6404Z" fill="white"/>
                    <path d="M228.078 139.883H149.379C142.5 139.883 136.919 134.287 136.919 127.424V12.4595C136.919 5.59594 142.515 0 149.379 0H228.078C234.957 0 240.537 5.59594 240.537 12.4595V127.408C240.537 134.287 234.941 139.868 228.078 139.868V139.883ZM149.379 0.77292C142.933 0.77292 137.692 6.01332 137.692 12.4595V127.408C137.692 133.854 142.933 139.095 149.379 139.095H228.078C234.524 139.095 239.764 133.854 239.764 127.408V12.4595C239.764 6.01332 234.524 0.77292 228.078 0.77292H149.379V0.77292Z" fill="#ED5A6E"/>
                    <path d="M220.673 100.232H156.799C155.399 100.232 154.264 101.367 154.264 102.767C154.264 104.168 155.399 105.303 156.799 105.303H220.673C222.073 105.303 223.208 104.168 223.208 102.767C223.208 101.367 222.073 100.232 220.673 100.232Z" fill="#ECEDF3"/>
                    <path d="M220.673 116.974H156.799C155.399 116.974 154.264 118.109 154.264 119.509C154.264 120.909 155.399 122.044 156.799 122.044H220.673C222.073 122.044 223.208 120.909 223.208 119.509C223.208 118.109 222.073 116.974 220.673 116.974Z" fill="#ECEDF3"/>
                    <path d="M157.231 88.8085H145.869V77.5393H148.961V85.7168H157.231V88.8085Z" fill="#EA93E9"/>
                    <path d="M231.601 88.8085H220.24V85.7168H228.51V77.5393H231.601V88.8085Z" fill="#EA93E9"/>
                    <path d="M148.962 20.5133H145.87V9.1514H157.232V12.2431H148.962V20.5133Z" fill="#EA93E9"/>
                    <path d="M231.602 20.5134H228.51V12.2431H220.24V9.15143H231.602V20.5134Z" fill="#EA93E9"/>
                    <path d="M222.596 79.1971L220.119 82.3227L232.634 92.2415L235.111 89.116L222.596 79.1971Z" fill="#1C3FCA"/>
                    <path d="M261.581 118.528L228.283 92.2022C227.046 91.2283 226.861 89.4197 227.866 88.2139L229.875 85.7869C230.849 84.6121 232.581 84.4266 233.786 85.3696L267.084 111.695C268.32 112.669 268.506 114.478 267.501 115.683L265.492 118.11C264.518 119.285 262.786 119.471 261.581 118.528V118.528Z" fill="#8262F9"/>
                    <path d="M184.752 15.2349C163.219 20.1661 149.77 41.6224 154.701 63.1405C159.632 84.674 181.089 98.1228 202.607 93.1916C224.14 88.2604 237.589 66.8041 232.658 45.286C227.727 23.7525 206.27 10.3036 184.752 15.2349V15.2349ZM201.726 89.3116C182.341 93.7481 163.033 81.6442 158.597 62.2593C154.16 42.8745 166.264 23.567 185.649 19.1304C205.034 14.6938 224.341 26.7978 228.778 46.1826C233.214 65.5674 221.111 84.875 201.726 89.3116Z" fill="#1C3FCA"/>
                    <path opacity="0.1" d="M164.486 67.0914C162.492 46.1298 177.873 27.5179 198.835 25.5083C202.684 25.1373 206.456 25.3692 210.058 26.0957C204.153 23.0813 197.366 21.6128 190.286 22.293C170.5 24.1789 155.969 41.7551 157.855 61.5418C158.89 72.3473 164.61 81.5605 172.803 87.4037C168.258 81.7769 165.244 74.8052 164.502 67.0605L164.486 67.0914Z" fill="black"/>
                </svg>
                <Heading as="h4" className="text-dark dark:text-white87 mt-10 mb-3.5 text-lg font-medium">
                    Access Denied
                </Heading>
                <p className="text-body dark:text-white60 mb-0 text-14">
                    You don't have access to this page, <br /> Check your credentials or ask your admin to request access.
                </p>
            </div>
        </main>
    );
}

export default AccessDenied;
