import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const promotionRuleMenuRegistry = {
    core_promotionRule: {
        label: "Promotion Rule",
        link: PAGE_URL.PROMOTION_RULE_PAGE,
        key: PAGE_URL.PROMOTION_RULE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default promotionRuleMenuRegistry;
