import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const membershipPlanMenuRegistry = {
    core_membershipPlan: {
        label: "Membership Plan",
        link: PAGE_URL.MEMBERSHIP_PLAN_PAGE,
        key: PAGE_URL.MEMBERSHIP_PLAN_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default membershipPlanMenuRegistry;
