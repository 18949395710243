import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PortfolioStock = React.lazy(() =>
    import('../PortfolioStock')
);
const portfolioStockRoutesRegistry = [
    {
        path: PAGE_URL.PORTFOLIO_STOCK_PAGE,
        element: <PortfolioStock />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default portfolioStockRoutesRegistry;
