import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const promotionCouponMenuRegistry = {
    core_promotionCoupon: {
        label: "Promotion Coupon",
        link: PAGE_URL.PROMOTION_COUPON_PAGE,
        key: PAGE_URL.PROMOTION_COUPON_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default promotionCouponMenuRegistry;
