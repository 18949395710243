import { Suspense } from 'react';

function AllRoute(props) {
  const { layout: Layout, element, loading } = props;

  return (
    <Layout>
      <Suspense fallback={loading}>{element}</Suspense>
    </Layout>
  );
}

export default AllRoute;
