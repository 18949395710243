import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const bCIndustryActivityMenuRegistry = {
    company_bcIndustryActivity: {
        label: "Bc Industry Activity",
        link: PAGE_URL.B_C_INDUSTRY_ACTIVITY_PAGE,
        key: PAGE_URL.B_C_INDUSTRY_ACTIVITY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default bCIndustryActivityMenuRegistry;
