import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const bCBusinessSectorMenuRegistry = {
    company_bcBusinessSector: {
        label: "Bc Business Sector",
        link: PAGE_URL.B_C_BUSINESS_SECTOR_PAGE,
        key: PAGE_URL.B_C_BUSINESS_SECTOR_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default bCBusinessSectorMenuRegistry;
