import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import menuConfig from './MenuConfig';
import {usePermissions} from "@/contexts/PermissionContext";
import localStorageService from "@/utility/localStorageService";
import Storage from "@/constants/storage";

function MenuItems({ toggleCollapsed, collapsed }) {
  const { hasPermission } = usePermissions();
  const location = useLocation();
  const { topMenu } = useSelector((state) => {
    return { topMenu: state.ChangeLayoutMode.topMenu };
  });

  const getInitialSelectedKey = () => {
    return [location.pathname];
  };

  const getInitialOpenKeys = () => {
    const openKeys = localStorageService.get(Storage.MENU_OPEN_KEYS);
    if (openKeys) {
      return openKeys;
    }
  };

  const [selectedKeys, setSelectedKeys] = useState(getInitialSelectedKey);
  const [openKeys, setOpenKeys] = useState(getInitialOpenKeys);

  function getItem(label, key, icon, children, type) {
    return { key, icon, children, label, type };
  }

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const onClick = (item) => {
    if (item.keyPath && item.keyPath.length === 2 && item.keyPath[1]) {
      localStorageService.set(Storage.MENU_OPEN_KEYS, item.keyPath[1]);
    }
  };

  const items = [];

  const createNavLink = (child) => (
      <NavLink onClick={toggleCollapsed} to={child.link}>
        {child.label}
      </NavLink>
  );

  const createChildItem = (child) => {
    if (!hasPermission('read', child.resourceKey)) {
      return null;
    }
    return getItem(
        createNavLink(child),
        child.key,
        child.icon
    );
  };

  const createItem = (item) => {
    const children = item.children?.map(createChildItem).filter(Boolean) || [];
    if (children.length <= 0 && item.type !== 'group') {
      return null;
    }
    return getItem(
        !topMenu && item.label,
        item.key,
        item.icon,
        children,
        item.type
    );
  };
  menuConfig.map(createItem).forEach(item => items.push(item));

  const findMatchingKeys = (items, pathname, parents = []) => {
    let bestMatch = null;

    for (const item of items) {
      // Check for an exact match first
      if (item.key === pathname) {
        return { matchedKey: item.key, openKeys: parents.map(parent => parent.key) };
      }
      // Check if pathname starts with item.key and it's a better match
      if (pathname.startsWith(item.key) && (!bestMatch || item.key.length > bestMatch.matchedKey.length)) {
        bestMatch = { matchedKey: item.key, openKeys: parents.map(parent => parent.key) };
      }
      if (item.children) {
        const result = findMatchingKeys(item.children, pathname, [...parents, item]);
        if (result && (!bestMatch || result.matchedKey.length > bestMatch.matchedKey.length)) {
          bestMatch = result;
        }
      }
    }
    return bestMatch;
  };

  useEffect(() => {
    const result = findMatchingKeys(menuConfig, location?.pathname);
    if (result) {
      setSelectedKeys([result.matchedKey]);
      setOpenKeys(result.openKeys);
      localStorageService.set(Storage.MENU_OPEN_KEYS, result.openKeys);
    } else {
      localStorageService.remove(Storage.MENU_OPEN_KEYS);
      setSelectedKeys([]);
      setOpenKeys([]);
    }
  }, [location?.pathname]);





  return (
    <div>
      {collapsed?
          <Menu
              onOpenChange={onOpenChange}
              on={(e) => {
                console.log(e);
              }}
              onClick={onClick}
              mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
              selectedKeys={selectedKeys}
              overflowedIndicator={<UilEllipsisV />}
              items={items}
          />:
          <Menu
              onOpenChange={onOpenChange}
              on={(e) => {
                console.log(e);
              }}
              onClick={onClick}
              mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
              selectedKeys={selectedKeys}
              defaultOpenKeys={openKeys}
              openKeys={openKeys}
              overflowedIndicator={<UilEllipsisV />}
              items={items}
          />
      }
    </div>
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
  collapsed: propTypes.any,
};

export default MenuItems;
