import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const popupUserMenuRegistry = {
    crm_popupUser: {
        label: "Popup User",
        link: PAGE_URL.POPUP_USER_PAGE,
        key: PAGE_URL.POPUP_USER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default popupUserMenuRegistry;
