import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const contactFormMenuRegistry = {
    core_contactForm: {
        label: "Contact Form",
        link: PAGE_URL.CONTACT_FORM_PAGE,
        key: PAGE_URL.CONTACT_FORM_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default contactFormMenuRegistry;
