import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const campaignSaleMenuRegistry = {
    crm_campaignSale: {
        label: "Campaign Sale",
        link: PAGE_URL.CAMPAIGN_SALE_PAGE,
        key: PAGE_URL.CAMPAIGN_SALE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default campaignSaleMenuRegistry;
