import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const StockAnalysisReport = React.lazy(() =>
    import('../StockAnalysisReport')
);
const stockAnalysisReportRoutesRegistry = [
    {
        path: PAGE_URL.STOCK_ANALYSIS_REPORT_PAGE,
        element: <StockAnalysisReport />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default stockAnalysisReportRoutesRegistry;
