import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const bCEconomicSectorMenuRegistry = {
    company_bcEconomicSector: {
        label: "Bc Economic Sector",
        link: PAGE_URL.B_C_ECONOMIC_SECTOR_PAGE,
        key: PAGE_URL.B_C_ECONOMIC_SECTOR_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default bCEconomicSectorMenuRegistry;
