import React, {Suspense} from 'react';
import {useModalContext} from "@/contexts/ModalContext";
import modalRegistry from './ModalRegistry';
import {Spin} from "antd";

const ModalManager = () => {
    const { modals, closeModal } = useModalContext();
    return (
        <>
            {Object.keys(modals).map((modalName) => {
                const ModalComponent = modalRegistry[modalName];
                if (!ModalComponent) {
                    console.warn(`Modal component '${modalName}' not found or not exported as default.`);
                    return null;
                }
                const modalProps = modals[modalName] || {};
                const handleClose = () => closeModal(modalName, modalProps.onClose);
                return (
                    <Suspense fallback={
                        <div className="spin flex items-center justify-center h-[calc(100vh-132px)]">
                            <Spin />
                        </div>
                    }>
                        {
                            <ModalComponent
                                key={modalName}
                                handleClose={handleClose}
                                {...modalProps}
                                destroyOnClose={true}
                            />
                        }
                    </Suspense>
                );
            })}
        </>
    );
};

export default ModalManager;
