import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const MainDashboard = React.lazy(() =>
    import('../MainDashboard')
);
const dasboardRoutesRegistry = [
    {
        path: `${PAGE_URL.DASHBOARD_PAGE}/:id`,
        element: <MainDashboard />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default dasboardRoutesRegistry;
