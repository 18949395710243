import { Row } from 'antd';
// import SunIcon from '@iconscout/react-unicons/icons/uil-sun';
// import MoonIcon from '@iconscout/react-unicons/icons/uil-moon';
// import { useTheme } from '../contexts/ThemeContext';

const TopMenu = () => {
  // const { theme, toggleTheme } = useTheme();

  return (
    <Row className="w-full px-5" justify={'end'}>
     {/* <div onClick={toggleTheme} className="cursor-pointer">
        {theme === 'light' ? <MoonIcon /> : <SunIcon />}
      </div>*/}
    </Row>
  );
};

export default TopMenu;
