import { configureStore } from '@reduxjs/toolkit';
import ChangeLayoutModeReducer from './themeLayout/reducers';
import themeReducer from './slices/themeSlice';
import workflowReducer from './slices/workflowSlice';
import poupInfoReducer from './slices/poupInfoSlice';

const store = configureStore({
  reducer: {
    ChangeLayoutMode: ChangeLayoutModeReducer,
    theme: themeReducer,
    workflow: workflowReducer,
    poupInfo: poupInfoReducer,
  },
});

export default store;
