import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const notificationMsgTemplateMenuRegistry = {
    notification_notificationMsgTemplate: {
        label: "Notification Msg Template",
        link: PAGE_URL.NOTIFICATION_MSG_TEMPLATE_PAGE,
        key: PAGE_URL.NOTIFICATION_MSG_TEMPLATE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default notificationMsgTemplateMenuRegistry;
