import React, { createContext, useState, useContext, useCallback } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState({});

    const openModal = useCallback((modalName, modalProps) => {
        setModals((prevModals) => ({ ...prevModals, [modalName]: { isOpen: true, ...modalProps } }));
    }, []);

    const closeModal = useCallback((modalName, callback) => {
        setModals((prevModals) => ({ ...prevModals, [modalName]: { ...prevModals[modalName], isOpen: false } }));
        if (callback) {
            callback();
        }
    }, []);

    return (
        <ModalContext.Provider value={{ modals, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => useContext(ModalContext);
