import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const CustomField = React.lazy(() =>
    import('../CustomField')
);
const customFieldRoutesRegistry = [
    {
        path: PAGE_URL.CUSTOM_FIELD_PAGE,
        element: <CustomField />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default customFieldRoutesRegistry;
