import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const promotionCouponRedemptionMenuRegistry = {
    core_promotionCouponRedemption: {
        label: "Promotion Coupon Redemption",
        link: PAGE_URL.PROMOTION_COUPON_REDEMPTION_PAGE,
        key: PAGE_URL.PROMOTION_COUPON_REDEMPTION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default promotionCouponRedemptionMenuRegistry;
