import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const courseReviewMenuRegistry = {
    community_courseReview: {
        label: "Course Review",
        link: PAGE_URL.COURSE_REVIEW_PAGE,
        key: PAGE_URL.COURSE_REVIEW_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default courseReviewMenuRegistry;
