import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const UserForceSession = React.lazy(() =>
    import('../UserForceSession')
);
const userForceSessionRoutesRegistry = [
    {
        path: PAGE_URL.USER_FORCE_SESSION_PAGE,
        element: <UserForceSession />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default userForceSessionRoutesRegistry;
