import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const securitiesAccountFormMenuRegistry = {
    core_securitiesAccountForm: {
        label: "Securities Account Form",
        link: PAGE_URL.SECURITIES_ACCOUNT_FORM_PAGE,
        key: PAGE_URL.SECURITIES_ACCOUNT_FORM_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default securitiesAccountFormMenuRegistry;
