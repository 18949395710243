import axios from 'axios';
import { deleteLocalData } from '@/utility/localStorageControl';
import { getIsLogined } from '@/utility/auth';
import Storage from '@/constants/storage';
import localStorageService from "@/utility/localStorageService";

class MainAxios {
  static instance;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */

  // eslint-disable-next-line
  constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */

  static getInstance(config) {
    if (!MainAxios.instance) {
      MainAxios.instance = axios.create(config);
    }

    return MainAxios.instance;
  }

  static setInstance(config) {
    MainAxios.instance = axios.create(config);
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */
  // someBusinessLogic() {
  //   // ...
  // }
}

// variable
let isRefreshing = false;
const refreshSubscribers = [];

// functions
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((cb) => cb(accessToken));
};

// instance
const mainAxios = MainAxios.getInstance({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const handleRefreshToken = async (refreshToken, isRequest, originalConfig) => {
  if (refreshToken && !isRefreshing) {
    isRefreshing = true;

    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/general/auth/refresh-token`,
        {
          refreshToken,
          clientId: "ops"
        },
      );

      const newToken = response?.data?.data?.accessToken;
      const newRefreshToken = response?.data?.data?.refreshToken;

      if (!newToken) {
        deleteLocalData();
      }

      localStorageService.set(Storage.ACCESS_TOKEN, newToken);
      localStorageService.set(Storage.REFRESH_TOKEN, newRefreshToken);

      onTokenRefreshed(newToken);

      if (isRequest) {
        const config = {
          ...originalConfig,
          headers: {
            ...originalConfig.headers,
            Authorization: `Bearer ${response.data.data.accessToken}`,
          },
        };

        return mainAxios(config);
      }
    } catch (error) {
      deleteLocalData();

      return Promise.reject(error);
    } finally {
      isRefreshing = false;
    }
  }
};

mainAxios.interceptors.request.use(
  async (config) => {
    if (typeof window === 'undefined') return config;

    const token = localStorageService.get(Storage.ACCESS_TOKEN);
    const refreshToken = localStorageService.get(Storage.REFRESH_TOKEN);

    if (token && config && config?.headers) {
      if (
        !config.url?.includes('/auth/login') &&
        !config.url?.includes('/auth/verify-2fa') &&
        !getIsLogined() &&
        refreshToken
      ) {
        handleRefreshToken(refreshToken, false, config);

        // Create a new promise to wait for the token refresh
        return new Promise((resolve) => {
          subscribeTokenRefresh((accessToken) => {
            const configHere = {
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            };
            resolve(configHere);
          });
        });
      }

      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

mainAxios.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },

  async (error) => {
    if (error.response?.status === 401 && typeof window !== 'undefined') {
      localStorageService.remove(Storage.ACCESS_TOKEN);
      localStorageService.remove(Storage.REFRESH_TOKEN);
      window.location.replace('/');
    }

    const originalConfig = error?.config;

    if (typeof window === 'undefined') return originalConfig;

    // const refreshToken = getCookie(Storage.REFRESH_TOKEN);

    if (originalConfig.url !== '/auth/login' && error.response) {
      // Access Token was expired
      if (error.response?.status === 401) {
        // handleRefreshToken(refreshToken, true, originalConfig);

        const retryOriginalRequest = new Promise((resolve) => {
          subscribeTokenRefresh((accessToken) => {
            const config = {
              ...originalConfig,
              headers: {
                ...originalConfig.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            };

            resolve(mainAxios(config));
          });
        });

        return retryOriginalRequest;
      }
    }

    return Promise.reject(error);
  },
);

export default mainAxios;
