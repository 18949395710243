import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const UserAuthority = React.lazy(() =>
    import('../UserAuthority')
);

const UserAuthorityDetail = React.lazy(() =>
    import('../UserAuthorityDetail')
);
const userAuthorityRoutesRegistry = [
    {
        path: PAGE_URL.USER_AUTHORITY_PAGE,
        element: <UserAuthority />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.USER_AUTHORITY_PAGE}/:id`,
        element: <UserAuthorityDetail />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default userAuthorityRoutesRegistry;
