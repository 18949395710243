import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const companyNoteMenuRegistry = {
    personalize_companyNote: {
        label: "Company Note",
        link: PAGE_URL.COMPANY_NOTE_PAGE,
        key: PAGE_URL.COMPANY_NOTE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default companyNoteMenuRegistry;
