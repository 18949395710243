import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const bCIndustryGroupMenuRegistry = {
    company_bcIndustryGroup: {
        label: "Bc Industry Group",
        link: PAGE_URL.B_C_INDUSTRY_GROUP_PAGE,
        key: PAGE_URL.B_C_INDUSTRY_GROUP_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default bCIndustryGroupMenuRegistry;
