import { Spin } from 'antd';

function Loading() {
  return (
    <div className="spin flex items-center justify-center min-h-screen">
      <Spin />
    </div>
  );
}

export default Loading;
