import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const securitiesAccountMenuRegistry = {
    core_securitiesAccount: {
        label: "Securities Account",
        link: PAGE_URL.SECURITIES_ACCOUNT_PAGE,
        key: PAGE_URL.SECURITIES_ACCOUNT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default securitiesAccountMenuRegistry;
