import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const NotificationMsgTemplate = React.lazy(() =>
    import('../NotificationMsgTemplate')
);
const notificationMsgTemplateRoutesRegistry = [
    {
        path: PAGE_URL.NOTIFICATION_MSG_TEMPLATE_PAGE,
        element: <NotificationMsgTemplate />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default notificationMsgTemplateRoutesRegistry;
