import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const portfolioWalletMarginHistoryMenuRegistry = {
    personalize_portfolioWalletMarginHistory: {
        label: "Portfolio Wallet Margin History",
        link: PAGE_URL.PORTFOLIO_WALLET_MARGIN_HISTORY_PAGE,
        key: PAGE_URL.PORTFOLIO_WALLET_MARGIN_HISTORY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default portfolioWalletMarginHistoryMenuRegistry;
