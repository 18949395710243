console.time('modal-registry');
const importAll = (r) => r.keys().map(r);
const relationModules = importAll(require.context('../modules', true, /registry\/modal\.js$/));

const allModals = relationModules.reduce((acc, module) => {
    if (module.default) {
        return {...acc, ...module.default};
    }
    return acc;
}, {});
console.timeEnd('modal-registry');
export default allModals;
