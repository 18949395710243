import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const Tags = React.lazy(() =>
    import('../Tags')
);
const TagDetail = React.lazy(() =>
    import('../TagDetail')
);
const tagsRoutesRegistry = [
    {
        path: PAGE_URL.TAGS_PAGE,
        element: <Tags />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.TAGS_PAGE}/:id`,
        element: <TagDetail />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default tagsRoutesRegistry;
