import React from 'react';
import allModals from "./ModalLoader";

const PopupUserInfo = React.lazy(() => import('@/modules/ModuleCore/User/components/PoupInfo/PopupUserInfo'));

const modalRegistry = {
    modalUserInfo: PopupUserInfo,
    ...allModals
};
console.log("modalRegistry", modalRegistry);
export default modalRegistry;
