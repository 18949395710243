import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const AppReleaseNotices = React.lazy(() =>
    import('../AppReleaseNotices')
);
const appReleaseNoticesRoutesRegistry = [
    {
        path: PAGE_URL.APP_RELEASE_NOTICES_PAGE,
        element: <AppReleaseNotices />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default appReleaseNoticesRoutesRegistry;
