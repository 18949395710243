import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const Order = React.lazy(() =>
    import('../Order')
);
const orderRoutesRegistry = [
    {
        path: PAGE_URL.ORDER_PAGE,
        element: <Order />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default orderRoutesRegistry;
