const PageUrl = {
  HOME_PAGE: `/`,
  ADMIN_PAGE: `/admin`,
  PROFILE_PAGE: `/admin/profile`,

  AUTOMATION_FLOW_PAGE: `/admin/automations/flow`,
  AUTOMATION_EXECUTION_PAGE: `/admin/automations/execution`,
  AUTOMATION_EXECUTION_DETAIL_2_PAGE: `/admin/automations/execution-detail`,
  AUTOMATION_DEFINITION_PAGE: `/admin/automations/definition`,
  AUTOMATION_DEFINITION_DETAIL_2_PAGE: `/admin/automations/definition-detail`,

  WORKFLOW_SEARCH_PAGE: `/admin/sim-workflow/workflows`,
  WORKFLOW_TASK_SEARCH_PAGE: `/admin/sim-workflow/search/tasks`,
  WORKFLOW_EXECUTION_PAGE: `/admin/sim-workflow/execution`,
  WORKFLOW_USER_EXECUTION_PAGE: `/admin/sim-workflow/user-execution`,
  WORKFLOW_DEFINITION_PAGE: `/admin/sim-workflow/workflowDefs`,
  WORKFLOW_DEFINITION_DETAIL_PAGE: `/admin/sim-workflow/workflowDef`,
  WORKFLOW_TASK_DEFINITION_PAGE: `/admin/sim-workflow/taskDefs`,
  WORKFLOW_TASK_DEFINITION_DETAIL_PAGE: `/admin/sim-workflow/taskDef`,
  WORKFLOW_EVENT_HANDLER_DEFINITION_PAGE: `/admin/sim-workflow/eventHandlerDef`,
  WORKFLOW_TASK_QUEUE_PAGE: `/admin/sim-workflow/taskQueue`,
  WORKFLOW_WORKBENCH_PAGE: `/admin/sim-workflow/workbench`,
  WORKFLOW_KITCHEN_PAGE: `/admin/sim-workflow/kitchen`,
  WORKFLOW_KITCHEN_DIAGRAM_PAGE: `/admin/sim-workflow/kitchen/diagram`,
  WORKFLOW_KITCHEN_EXAMPLE_PAGE: `/admin/sim-workflow/kitchen/examples`,
  WORKFLOW_KITCHEN_GANTT_PAGE: `/admin/sim-workflow/kitchen/gantt`,

};

export default PageUrl;
