import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const Course = React.lazy(() =>
    import('../Course')
);
const courseRoutesRegistry = [
    {
        path: PAGE_URL.COURSE_PAGE,
        element: <Course />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default courseRoutesRegistry;
