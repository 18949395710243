import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const segmentsMenuRegistry = {
    crm_segments: {
        label: "Segments",
        link: PAGE_URL.SEGMENTS_PAGE,
        key: PAGE_URL.SEGMENTS_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default segmentsMenuRegistry;
