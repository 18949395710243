import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const segmentUserMenuRegistry = {
    crm_segmentUser: {
        label: "Segment User",
        link: PAGE_URL.SEGMENT_USER_PAGE,
        key: PAGE_URL.SEGMENT_USER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default segmentUserMenuRegistry;
