import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const UserReferralReward = React.lazy(() =>
    import('../UserReferralReward')
);
const userReferralRewardRoutesRegistry = [
    {
        path: PAGE_URL.USER_REFERRAL_REWARD_PAGE,
        element: <UserReferralReward />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default userReferralRewardRoutesRegistry;
