import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const workflowTriggerMenuRegistry = {
    crm_workflowTrigger: {
        label: "Workflow Trigger",
        link: PAGE_URL.WORKFLOW_TRIGGER_PAGE,
        key: PAGE_URL.WORKFLOW_TRIGGER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default workflowTriggerMenuRegistry;
