import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const integrationLogMenuRegistry = {
    core_integrationLog: {
        label: "Integration Log",
        link: PAGE_URL.INTEGRATION_LOG_PAGE,
        key: PAGE_URL.INTEGRATION_LOG_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default integrationLogMenuRegistry;
