import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userCashTransactionMenuRegistry = {
    core_userCashTransaction: {
        label: "User Cash Transaction",
        link: PAGE_URL.USER_CASH_TRANSACTION_PAGE,
        key: PAGE_URL.USER_CASH_TRANSACTION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userCashTransactionMenuRegistry;
