import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PopupInfo = React.lazy(() =>
    import('../PopupInfo')
);
const popupInfoRoutesRegistry = [
    {
        path: PAGE_URL.POPUP_INFO_PAGE,
        element: <PopupInfo />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default popupInfoRoutesRegistry;
