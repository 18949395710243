import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const MembershipPlanUser = React.lazy(() =>
    import('../MembershipPlanUser')
);
const membershipPlanUserRoutesRegistry = [
    {
        path: PAGE_URL.MEMBERSHIP_PLAN_USER_PAGE,
        element: <MembershipPlanUser />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default membershipPlanUserRoutesRegistry;
