import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const NotificationModule = React.lazy(() =>
    import('../NotificationModule')
);
const notificationModuleRoutesRegistry = [
    {
        path: PAGE_URL.NOTIFICATION_MODULE_PAGE,
        element: <NotificationModule />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default notificationModuleRoutesRegistry;
