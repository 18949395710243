import { getIsLogined } from '@/utility/auth';
import React, { Suspense } from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {usePermissions} from "@/contexts/PermissionContext";
import AccessDenied from "@/components/access-denied/access-denied";

function PrivateRoute(props) {
  const { layout: Layout, element, loading, resourceKey } = props;
  const { hasPermission } = usePermissions();
  const location = useLocation();
  const currentPath = location.pathname + location.search; // This includes the path and query string if present

  if (!getIsLogined()) {
    return <Navigate to={`/?return-url=${encodeURIComponent(currentPath)}`} replace={true} />;
  }

  if (resourceKey != null && !hasPermission("read", resourceKey)) {
    return <AccessDenied/>;
  }

  return (
    <Layout>
      <Suspense fallback={loading}>{element}</Suspense>
    </Layout>
  );
}

export default PrivateRoute;
