import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PromotionCouponRedemption = React.lazy(() =>
    import('../PromotionCouponRedemption')
);
const promotionCouponRedemptionRoutesRegistry = [
    {
        path: PAGE_URL.PROMOTION_COUPON_REDEMPTION_PAGE,
        element: <PromotionCouponRedemption />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default promotionCouponRedemptionRoutesRegistry;
