import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const tagsMenuRegistry = {
    crm_tags: {
        label: "Tags",
        link: PAGE_URL.TAGS_PAGE,
        key: PAGE_URL.TAGS_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default tagsMenuRegistry;
