import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const smSubscribeTrackingMenuRegistry = {
    notification_smSubscribeTracking: {
        label: "Sm Subscribe Tracking",
        link: PAGE_URL.SM_SUBSCRIBE_TRACKING_PAGE,
        key: PAGE_URL.SM_SUBSCRIBE_TRACKING_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default smSubscribeTrackingMenuRegistry;
