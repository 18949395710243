import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const ContactForm = React.lazy(() =>
    import('../ContactForm')
);
const contactFormRoutesRegistry = [
    {
        path: PAGE_URL.CONTACT_FORM_PAGE,
        element: <ContactForm />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default contactFormRoutesRegistry;
