import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PromotionRule = React.lazy(() =>
    import('../PromotionRule')
);
const PromotionDetails = React.lazy(() =>
    import('../PromotionDetails')
);
const promotionRuleRoutesRegistry = [
    {
        path: PAGE_URL.PROMOTION_RULE_PAGE,
        element: <PromotionRule />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.PROMOTION_RULE_PAGE}/:id`,
        element: <PromotionDetails />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default promotionRuleRoutesRegistry;
