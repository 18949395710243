import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const orderMenuRegistry = {
    core_order: {
        label: "Order",
        link: PAGE_URL.ORDER_PAGE,
        key: PAGE_URL.ORDER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default orderMenuRegistry;
