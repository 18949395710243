import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PortfolioWalletMarginHistory = React.lazy(() =>
    import('../PortfolioWalletMarginHistory')
);
const portfolioWalletMarginHistoryRoutesRegistry = [
    {
        path: PAGE_URL.PORTFOLIO_WALLET_MARGIN_HISTORY_PAGE,
        element: <PortfolioWalletMarginHistory />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default portfolioWalletMarginHistoryRoutesRegistry;
