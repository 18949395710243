import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const zoneMenuRegistry = {
    crm_zone: {
        label: "Zone",
        link: PAGE_URL.ZONE_PAGE,
        key: PAGE_URL.ZONE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default zoneMenuRegistry;
