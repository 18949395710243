import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const MiniAppConfig = React.lazy(() =>
    import('../MiniAppConfig')
);
const miniAppConfigRoutesRegistry = [
    {
        path: PAGE_URL.MINI_APP_CONFIG_PAGE,
        element: <MiniAppConfig />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default miniAppConfigRoutesRegistry;
