import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const wallOfLoveMenuRegistry = {
    crm_wallOfLove: {
        label: "Wall Of Love",
        link: PAGE_URL.WALL_OF_LOVE_PAGE,
        key: PAGE_URL.WALL_OF_LOVE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default wallOfLoveMenuRegistry;
