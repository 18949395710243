import PageUrl from '@/constants/page-url';
import Storage from '@/constants/storage';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import mainAxios from '../../../config/dataService/axiosInstance';
import { Popover } from '../../popup/popup';
import localStorageService from "@/utility/localStorageService";

const AuthInfo = React.memo(() => {
  // ______________________ new code ______________________
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await mainAxios.get(`/api/general/user/current`);
        setCurrentUser(response.data);
        const userInfo = {
          userId: response?.data?.id,
          email: response?.data?.email,
          imageUrl: response?.data?.imageUrl,
          fullName: response?.data?.fullName,
        }
        localStorageService.set(Storage.USER_INFO, JSON.stringify(userInfo))
      } catch (error) {
        setCurrentUser(error?.response?.data?.data);
      }
    })();
  }, []);

  // ______________________ old code ______________________
  const SignOut = () => {
    localStorageService.remove(Storage.ACCESS_TOKEN);
    localStorageService.remove(Storage.REFRESH_TOKEN);
    window.location.replace(`${PageUrl.HOME_PAGE}`);
  };

  const userContent = (
    <div>
      <div className="min-w-[280px] sm:min-w-full">
        <ul className="mb-0">
          <li>
            <Link
              to={`${PageUrl.PROFILE_PAGE}`}
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilUser className="w-3.5 h-3.5 ltr:mr-3 rtl:ml-3" /> Profile
            </Link>
          </li>
        </ul>

        <Link
          to="#"
          onClick={SignOut}
          className="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white60 dark:text-white87 mx-[-15px] mb-[-15px] rounded-b-6"
        >
          <UilSignout className="w-3.5 h-3.5 ltr:mr-3 rtl:ml-3" /> Sign Out
        </Link>
      </div>
    </div>
  );

  return (
    <div className="flex items-center justify-end flex-auto">
      <div className="flex ltr:ml-3 rtl:mr-3 ltr:mr-4 rtl:ml-4 ssm:mr-0 ssm:rtl:ml-0">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link
            to="#"
            className="flex items-center text-light whitespace-nowrap"
          >
            {currentUser?.imageUrl && <Avatar src={currentUser.imageUrl} />}

            <span className="ltr:mr-1.5 rtl:ml-1.5 ltr:ml-2.5 rtl:mr-2.5 text-body dark:text-white60 text-sm font-medium md:hidden">
              {currentUser?.fullName}
            </span>

            <UilAngleDown className="w-3.5 h-3.5 ltr:md:ml-[5px] rtl:md:mr-[5px]" />
          </Link>
        </Popover>
      </div>
    </div>
  );
});

export default AuthInfo;
