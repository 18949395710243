import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const courseLessonMenuRegistry = {
    community_courseLesson: {
        label: "Course Lesson",
        link: PAGE_URL.COURSE_LESSON_PAGE,
        key: PAGE_URL.COURSE_LESSON_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default courseLessonMenuRegistry;
