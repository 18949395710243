import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PermissionGroup = React.lazy(() =>
    import('../PermissionGroup')
);
const PermissionGroupDetails = React.lazy(() =>
    import('../PermissionGroupDetails')
);
const permissionGroupRoutesRegistry = [
    {
        path: PAGE_URL.PERMISSION_GROUP_PAGE,
        element: <PermissionGroup />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.PERMISSION_GROUP_PAGE}/:id`,
        element: <PermissionGroupDetails />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default permissionGroupRoutesRegistry;
