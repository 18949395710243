import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const pnlConfigSystemMenuRegistry = {
    personalize_pnlConfigSystem: {
        label: "Pnl Config System",
        link: PAGE_URL.PNL_CONFIG_SYSTEM_PAGE,
        key: PAGE_URL.PNL_CONFIG_SYSTEM_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default pnlConfigSystemMenuRegistry;
