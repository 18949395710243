import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userScreenerMenuRegistry = {
    personalize_userScreener: {
        label: "User Screener",
        link: PAGE_URL.USER_SCREENER_PAGE,
        key: PAGE_URL.USER_SCREENER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userScreenerMenuRegistry;
