import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userCashPlanMenuRegistry = {
    core_userCashPlan: {
        label: "User Cash Plan",
        link: PAGE_URL.USER_CASH_PLAN_PAGE,
        key: PAGE_URL.USER_CASH_PLAN_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userCashPlanMenuRegistry;
