import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const watchlistItemMenuRegistry = {
    personalize_watchlistItem: {
        label: "Watchlist Item",
        link: PAGE_URL.WATCHLIST_ITEM_PAGE,
        key: PAGE_URL.WATCHLIST_ITEM_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default watchlistItemMenuRegistry;
