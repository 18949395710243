import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const courseMenuRegistry = {
    community_course: {
        label: "Course",
        link: PAGE_URL.COURSE_PAGE,
        key: PAGE_URL.COURSE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default courseMenuRegistry;
