import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const SegmentUser = React.lazy(() =>
    import('../SegmentUser')
);
const segmentUserRoutesRegistry = [
    {
        path: PAGE_URL.SEGMENT_USER_PAGE,
        element: <SegmentUser />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default segmentUserRoutesRegistry;
