import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const MembershipPlan = React.lazy(() =>
    import('../MembershipPlan')
);
const membershipPlanRoutesRegistry = [
    {
        path: PAGE_URL.MEMBERSHIP_PLAN_PAGE,
        element: <MembershipPlan />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default membershipPlanRoutesRegistry;
