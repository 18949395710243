import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userAuthorityMenuRegistry = {
    core_userAuthority: {
        label: "User Authority",
        link: PAGE_URL.USER_AUTHORITY_PAGE,
        key: PAGE_URL.USER_AUTHORITY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userAuthorityMenuRegistry;
