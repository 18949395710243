import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const BCIndustry = React.lazy(() =>
    import('../BCIndustry')
);
const bCIndustryRoutesRegistry = [
    {
        path: PAGE_URL.B_C_INDUSTRY_PAGE,
        element: <BCIndustry />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default bCIndustryRoutesRegistry;
