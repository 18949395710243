import React, {createContext, useContext, useMemo, useState} from 'react';
import jwtDecode from 'jwt-decode';

const PermissionContext = createContext();

export const PermissionProvider = ({ initialToken, children }) => {
  const [token, setToken] = useState(initialToken);
  const decodedToken = useMemo(() => {
    if(!token || token ==='') {
      return "";
    }
    const  decodeJwt = jwtDecode(token)
    return decodeJwt.auth;
  }, [token]);
  const permissions = decodedToken.split(',');

  const hasRole = (role) => permissions.includes(role);
  const hasPermission = (action, key) => {
    const permissionString = `${action}:${key}`;
    return permissions.includes(permissionString) || hasRole('ROLE_ADMIN');
  };

  const updateToken = (newToken) => {
    setToken(newToken);
  };

  return (
      <PermissionContext.Provider value={{ hasRole, hasPermission, updateToken }}>
        {children}
      </PermissionContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionContext);
