import Storage from '@/constants/storage';
import localStorageService from "@/utility/localStorageService";

const getItem = (key) => {
  const data = localStorageService.get(key);

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const setItem = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  return localStorageService.set(key, stringify);
};

const removeItem = (key) => {
  localStorageService.remove(key);
};

const deleteLocalData = (options) => {
  removeItem(Storage.ACCESS_TOKEN);
  removeItem(Storage.REFRESH_TOKEN);

  if (options?.redirectUrl) {
    window.location.href = options?.redirectUrl;
  } else {
    window.location.reload();
  }
};

export { getItem, setItem, removeItem, deleteLocalData };
