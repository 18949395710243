import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const notificationTypeGroupMenuRegistry = {
    notification_notificationTypeGroup: {
        label: "Notification Type Group",
        link: PAGE_URL.NOTIFICATION_TYPE_GROUP_PAGE,
        key: PAGE_URL.NOTIFICATION_TYPE_GROUP_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default notificationTypeGroupMenuRegistry;
