import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const NebulaRewardTransaction = React.lazy(() =>
    import('../NebulaRewardTransaction')
);
const nebulaRewardTransactionRoutesRegistry = [
    {
        path: PAGE_URL.NEBULA_REWARD_TRANSACTION_PAGE,
        element: <NebulaRewardTransaction />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default nebulaRewardTransactionRoutesRegistry;
