import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const customFieldMenuRegistry = {
    crm_customField: {
        label: "Custom Field",
        link: PAGE_URL.CUSTOM_FIELD_PAGE,
        key: PAGE_URL.CUSTOM_FIELD_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default customFieldMenuRegistry;
