import Styled from 'styled-components';
import { Popover } from 'antd';

const Content = Styled.div`  
  
`;
const Title = Styled.p`
`;
const PopoverStyle = Styled(Popover)` 
  
`;
export { Content, PopoverStyle, Title };
