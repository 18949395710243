import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const stockAnalysisReportMenuRegistry = {
    company_stockAnalysisReport: {
        label: "Stock Analysis Report",
        link: PAGE_URL.STOCK_ANALYSIS_REPORT_PAGE,
        key: PAGE_URL.STOCK_ANALYSIS_REPORT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default stockAnalysisReportMenuRegistry;
