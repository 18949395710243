import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const taSignalConfigMenuRegistry = {
    personalize_taSignalConfig: {
        label: "Ta Signal Config",
        link: PAGE_URL.TA_SIGNAL_CONFIG_PAGE,
        key: PAGE_URL.TA_SIGNAL_CONFIG_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default taSignalConfigMenuRegistry;
