import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const watchlistMenuRegistry = {
    personalize_watchlist: {
        label: "Watchlist",
        link: PAGE_URL.WATCHLIST_PAGE,
        key: PAGE_URL.WATCHLIST_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default watchlistMenuRegistry;
