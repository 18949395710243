import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const permissionGroupMappingMenuRegistry = {
    core_permissionGroupMapping: {
        label: "Permission Group Mapping",
        link: PAGE_URL.PERMISSION_GROUP_MAPPING_PAGE,
        key: PAGE_URL.PERMISSION_GROUP_MAPPING_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default permissionGroupMappingMenuRegistry;
