import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const courseStudentMenuRegistry = {
    community_courseStudent: {
        label: "Course Student",
        link: PAGE_URL.COURSE_STUDENT_PAGE,
        key: PAGE_URL.COURSE_STUDENT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default courseStudentMenuRegistry;
