import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const FileManager = React.lazy(() =>
    import('../FileManagerPage')
);
const fileManagerRoutesRegistry = [
    {
        path: PAGE_URL.FILE_MANAGER_PAGE,
        element: <FileManager />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.FILE_MANAGER_PAGE + "/*",
        element: <FileManager />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default fileManagerRoutesRegistry;
