import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const dasboardMenuRegistry = {
    crm_dashboard1: {
        label: "Dashboard",
        link: `${PAGE_URL.DASHBOARD_PAGE}/1`,
        key: `${PAGE_URL.DASHBOARD_PAGE}/1`,
        resourceKey: PERMISSION_KEY + "_10"
    },
    crm_dashboard10: {
        label: "Simplize Metrics",
        link: `${PAGE_URL.DASHBOARD_PAGE}/10`,
        key: `${PAGE_URL.DASHBOARD_PAGE}/10`,
        resourceKey: PERMISSION_KEY + "_10"
    },
    crm_dashboard11: {
        label: "Simplize Reporting",
        link: `${PAGE_URL.DASHBOARD_PAGE}/11`,
        key: `${PAGE_URL.DASHBOARD_PAGE}/11`,
        resourceKey: PERMISSION_KEY + "_11"
    },
    crm_dashboard7: {
        label: "Dashboard Marketing",
        link: `${PAGE_URL.DASHBOARD_PAGE}/7`,
        key: `${PAGE_URL.DASHBOARD_PAGE}/7`,
        resourceKey: PERMISSION_KEY + "_7"
    }
}

export default dasboardMenuRegistry;
