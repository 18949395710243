import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const userInfoMenuRegistry = {
    crm_userInfo: {
        label: "User Info",
        link: PAGE_URL.USER_INFO_PAGE,
        key: PAGE_URL.USER_INFO_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default userInfoMenuRegistry;
