import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PortfolioList = React.lazy(() =>
    import('../PortfolioList')
);
const PortfolioListIntegration = React.lazy(() =>
    import('../PortfolioListIntegration')
);
const portfolioListRoutesRegistry = [
    {
        path: PAGE_URL.PORTFOLIO_LIST_PAGE,
        element: <PortfolioList />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.PORTFOLIO_LIST_INTEGRATION_PAGE,
        element: <PortfolioListIntegration />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default portfolioListRoutesRegistry;
