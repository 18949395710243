import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const nebulaRewardTransactionMenuRegistry = {
    personalize_nebulaRewardTransaction: {
        label: "Nebula Reward Transaction",
        link: PAGE_URL.NEBULA_REWARD_TRANSACTION_PAGE,
        key: PAGE_URL.NEBULA_REWARD_TRANSACTION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default nebulaRewardTransactionMenuRegistry;
