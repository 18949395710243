import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const CourseLesson = React.lazy(() =>
    import('../CourseLesson')
);
const courseLessonRoutesRegistry = [
    {
        path: PAGE_URL.COURSE_LESSON_PAGE,
        element: <CourseLesson />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default courseLessonRoutesRegistry;
