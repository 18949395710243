import Loading from '@/components/Loading';
import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from 'react-router-dom';
import PublicRoute from './PublicRoute';
import AllRoute from './AllRoute';
import PrivateRoute from './PrivateRoute';
import NotFound from '@/components/NotFound';
import PageUrl from '@/constants/page-url';
import allRoutes from "./RouterLoader";
import RESOURCE_KEY from "@/constants/resource-key";

const AdminLayoutComponent = React.lazy(() => import('@/layout/AdminLayout'));

const AdminLayout = ({ children }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <AdminLayoutComponent>{children}</AdminLayoutComponent>
    </React.Suspense>
  );
};

const AdminLayoutWorkflow = ({ children }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <AdminLayoutComponent>
        <div className={'workflow-container'}>{children}</div>
      </AdminLayoutComponent>
    </React.Suspense>
  );
};

/*const NoLayout = ({ children }) => {
  return <React.Suspense fallback={<Loading />}>{children}</React.Suspense>;
};*/

const Profile = React.lazy(() => import('@/modules/ModuleProfile/Profile'));
const SearchExecution = React.lazy(() =>
  import('@/modules/ModuleAutomation/SearchExecution/SearchExecution')
);
const ExecutionDetail = React.lazy(() =>
  import('@/modules/ModuleAutomation/ExecutionDetail/ExecutionDetail')
);
const Definition = React.lazy(() =>
  import('@/modules/ModuleAutomation/Definition/Definition')
);
const DefinitionDetail = React.lazy(() =>
  import('@/modules/ModuleAutomation/DefinitionDetail/DefinitionDetail')
);
const SignIn = React.lazy(() => import('@/modules/ModuleAuth/SignIn'));

const WorkflowSearch = React.lazy(() =>
  import('@/workflow/pages/executions/WorkflowSearch')
);
const UserExecutionSearch = React.lazy(() =>
  import('@/workflow/pages/executions/UserExecutionSearch')
);
const TaskSearch = React.lazy(() =>
  import('@/workflow/pages/executions/TaskSearch')
);
const Execution = React.lazy(() =>
  import('@/workflow/pages/execution/Execution')
);
const WorkflowDefinitions = React.lazy(() =>
  import('@/workflow/pages/definitions/Workflow')
);
const WorkflowDefinition = React.lazy(() =>
  import('@/workflow/pages/definition/WorkflowDefinition')
);
const TaskDefinitions = React.lazy(() =>
  import('@/workflow/pages/definitions/Task')
);
const TaskDefinition = React.lazy(() =>
  import('@/workflow/pages/definition/TaskDefinition')
);
const EventHandlerDefinition = React.lazy(() =>
  import('@/workflow/pages/definition/EventHandler')
);
const EventHandlerDefinitions = React.lazy(() =>
  import('@/workflow/pages/definitions/EventHandler')
);
const TaskQueue = React.lazy(() => import('@/workflow/pages/misc/TaskQueue'));
const Workbench = React.lazy(() =>
  import('@/workflow/pages/workbench/Workbench')
);
const KitchenSink = React.lazy(() =>
  import('@/workflow/pages/kitchensink/KitchenSink')
);
const DiagramTest = React.lazy(() =>
  import('@/workflow/pages/kitchensink/DiagramTest')
);
const Examples = React.lazy(() =>
  import('@/workflow/pages/kitchensink/Examples')
);
const Gantt = React.lazy(() => import('@/workflow/pages/kitchensink/Gantt'));

const User = React.lazy(() => import('@/modules/ModuleCore/User'));

const ALL_ROUTES = [
  {
    path: `/about-us`,
    element: <h1>{`About-us`}</h1>,
    loading: <Loading />,
    layout: AdminLayout,
  },
];

const PUBLIC_ROUTES = [
  {
    path: `/`,
    element: <SignIn />,
    loading: <Loading />,
    layout: React.Fragment,
  },
];

const PRIVATE_ROUTES = [
  {
    path: `${PageUrl.ADMIN_PAGE}`,
    element: <User />,
    loading: <Loading />,
    layout: AdminLayout
  },
  {
    path: `${PageUrl.PROFILE_PAGE}`,
    element: <Profile />,
    loading: <Loading />,
    layout: AdminLayout
  },
  {
    path: `${PageUrl.AUTOMATION_EXECUTION_PAGE}`,
    element: <SearchExecution />,
    loading: <Loading />,
    layout: AdminLayout,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.AUTOMATION_EXECUTION_PAGE}/:id`,
    element: <ExecutionDetail />,
    loading: <Loading />,
    layout: AdminLayout,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.AUTOMATION_DEFINITION_PAGE}`,
    element: <Definition />,
    loading: <Loading />,
    layout: AdminLayout,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.AUTOMATION_DEFINITION_PAGE}/:name`,
    element: <DefinitionDetail />,
    loading: <Loading />,
    layout: AdminLayout,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_SEARCH_PAGE}`,
    element: <WorkflowSearch />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_USER_EXECUTION_PAGE}`,
    element: <UserExecutionSearch />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_TASK_SEARCH_PAGE}/*`,
    element: <TaskSearch />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_EXECUTION_PAGE}/:id/:taskId?`,
    element: <Execution />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_DEFINITION_PAGE}`,
    element: <WorkflowDefinitions />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_DEFINITION_DETAIL_PAGE}/:name?/:version?`,
    element: <WorkflowDefinition />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_TASK_DEFINITION_PAGE}`,
    element: <TaskDefinitions />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_TASK_DEFINITION_DETAIL_PAGE}/:name?`,
    element: <TaskDefinition />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_EVENT_HANDLER_DEFINITION_PAGE}`,
    element: <EventHandlerDefinitions />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_EVENT_HANDLER_DEFINITION_PAGE}/:name`,
    element: <EventHandlerDefinition />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_TASK_QUEUE_PAGE}/:name?`,
    element: <TaskQueue />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_WORKBENCH_PAGE}`,
    element: <Workbench />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_KITCHEN_PAGE}`,
    element: <KitchenSink />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_KITCHEN_DIAGRAM_PAGE}`,
    element: <DiagramTest />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_KITCHEN_EXAMPLE_PAGE}`,
    element: <Examples />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
  {
    path: `${PageUrl.WORKFLOW_KITCHEN_GANTT_PAGE}`,
    element: <Gantt />,
    loading: <Loading />,
    layout: AdminLayoutWorkflow,
    resourceKey: RESOURCE_KEY.WORKFLOW_CORE
  },
];

function Routes() {
  return (
    <Router>
      <Switch>
        {ALL_ROUTES.map((item, index) => (
          <Route
            key={`all-route-${index}`}
            path={item.path}
            element={
              <AllRoute
                layout={item.layout}
                element={item.element}
                loading={item.loading}
              />
            }
          />
        ))}
        {PUBLIC_ROUTES.map((item, index) => (
          <Route
            key={`public-route-${index}`}
            path={item.path}
            element={
              <PublicRoute
                layout={item.layout}
                element={item.element}
                loading={item.loading}
              />
            }
          />
        ))}
        {PRIVATE_ROUTES.map((item, index) => (
          <Route
            key={`private-route-${index}`}
            path={item.path}
            element={
              <PrivateRoute
                layout={item.layout}
                element={item.element}
                loading={item.loading}
                resourceKey={item.resourceKey}
              />
            }
          />
        ))}
        {allRoutes.map((item, index) => (
            <Route
                key={`auto-private-route-${index}`}
                path={item.path}
                element={
                  <PrivateRoute
                      layout={item.layout}
                      element={item.element}
                      loading={item.loading}
                  />
                }
            />
        ))}
        <Route path="*" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Switch>
    </Router>
  );
}

export default Routes;
