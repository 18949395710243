import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const portfolioListMenuRegistry = {
    personalize_portfolioList: {
        label: "Portfolio List",
        link: PAGE_URL.PORTFOLIO_LIST_PAGE,
        key: PAGE_URL.PORTFOLIO_LIST_PAGE,
        resourceKey: PERMISSION_KEY
    },
    personalize_portfolioListIntegration: {
        label: "Portfolio List Integration",
        link: PAGE_URL.PORTFOLIO_LIST_INTEGRATION_PAGE,
        key: PAGE_URL.PORTFOLIO_LIST_INTEGRATION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default portfolioListMenuRegistry;
