const Storage = {
  PREFIX: 'sim',
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  COLLAPSED: 'collapsed',
  MENU_OPEN_KEYS: 'menu-open-keys',
  USER_INFO: "user-info",
  FILE_MANAGER_LAYOUT_VIEW: 'layout-view',
  FILE_MANAGER_BUCKET_NAME: 'file-manager-bucket-name',
  FILE_MANAGER_PATH: 'file-manager-path',
};

export default Storage;
