console.time('menu-registry');
const importAll = (r) => r.keys().map(r);
const registryModules = importAll(require.context('../modules', true, /registry\/menu\.js$/));
const menuRegistry = registryModules.reduce((acc, module) => {
    if (module.default) {
        return {...acc, ...module.default};
    }
    return acc;
}, {});
console.timeEnd('menu-registry');
export default menuRegistry;
