import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const WorkflowTrigger = React.lazy(() =>
    import('../WorkflowTrigger')
);
const workflowTriggerRoutesRegistry = [
    {
        path: PAGE_URL.WORKFLOW_TRIGGER_PAGE,
        element: <WorkflowTrigger />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default workflowTriggerRoutesRegistry;
