import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visibleTaskDetail: true,
};

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setVisibleTaskDetail: (state, action) => {
      state.visibleTaskDetail = action.payload;
    },
  },
});

export const { setVisibleTaskDetail } = workflowSlice.actions;

export default workflowSlice.reducer;
